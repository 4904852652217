@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--font-work-sans: 'Work Sans', sans-serif;
	--font-merriweather: 'Roboto', sans-serif;

	--font-size-sm: clamp(0.8rem, 0.08vw + 0.78rem, 0.84rem);
	--font-size-base: clamp(1rem, 0.23vw + 0.94rem, 1.13rem);
	--font-size-md: clamp(1.25rem, 0.45vw + 1.14rem, 1.5rem);
	--font-size-lg: clamp(1.56rem, 0.79vw + 1.36rem, 2rem);
	--font-size-xl: clamp(1.95rem, 1.29vw + 1.63rem, 2.66rem);
	--font-size-xxl: clamp(2.44rem, 2.02vw + 1.94rem, 3.55rem);
	--font-size-xxxl: clamp(3.05rem, 3.06vw + 2.29rem, 4.73rem);
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	color: inherit;
	text-decoration: none;
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */
	}
}


a.button {
	align-self: flex-start;
	background-color: theme('colors.white');
	border: 1px solid theme('colors.gold');
	border-radius: 5px;
	color: theme('colors.gold');
	display: block;
	font-family: var(--font-work-sans);
	font-size: 1rem;
	font-weight: 500;
	line-height: 1;
	text-transform: uppercase;
	transition: all 0.3s ease-in-out;
	padding: 0.75rem 1.125rem;

	&:hover,
	&:focus {
		background-color: theme('colors.gold');
		color: theme('colors.white');
	}
}

img {
	height: auto;
	max-width: 100%;
}

.radius {
	border-radius: 0 20px;
}

a.button {
	@apply bg-white border border-gold text-gold font-primary font-semibold uppercase rounded px-4 py-3 transition-colors;
}

a.button:hover,
a.button:focus {
	@apply bg-gold text-white;
}

@layer base {
	:root {
		--radius: 0.5rem;
	}
}

div[role="dialog"] button:not([aria-label]):has(svg) {
	display: none;
}
